import React, { createContext, useState, useEffect } from 'react'
import _, { set } from 'lodash'
import Api from './Api'
import constants from '@/core/utils/constants'
import helper from '@/core/utils/helper'
import { process } from '@/core/utils/ChatData'

export const GeneralContext = createContext(null)

export const GeneralProvider = ({ className, zIndex, children }) => {
  // for first load. then we clamp it to the context
  const [labels, setLabels] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [chatBotData, setChatBotData] = useState(null)
  const [currentStep, setCurrentStep] = useState('')

  const [showFacility, setShowFacility] = useState(
    null
    // {org_id: 20369}
  )

  const zIndexInt = parseInt(zIndex) || 1000000

  // to store halting parameters
  // { step,  sCodes, input, cue }
  const [stage, setStage] = useState()

  useEffect(() => {
    fetchTree()
  }, [])

  const onStageSetup = (scode, input = {}, operation = 'AND') => {
    // currently only USER will input

    // add parameters
    setStage(prev => {
      const _v = _.cloneDeep(prev) || {}
      let bucket = 'sCodes'
      if (operation === 'AND') {
        bucket = 'sCodes'
      } else if (operation === 'OR') {
        bucket = 'sCodes_or'
      }

      const currentSCodes = _v[bucket] || []
      // for scode: add scode to the string array, if not already there
      if (!currentSCodes.includes(scode)) {
        currentSCodes.push(scode)
      }
      _v[bucket] = currentSCodes
      // for input: replace the field with the new value
      _v.input = { ..._v.input, ...input }
      return _v
    })
  }

  const onStageClear = () => {
    setStage(null)
  }

  async function fetchTree() {
    setErrorMessage(null)
    try {
      const data = await Api.getTree()
      if (!data?.logicTree) {
        throw new Error('Oops, we lost connection to the server. Please try again later.')
      }

      setLabels(data?.labels)
      const botProcessedData = process(data?.logicTree)

      setChatBotData(botProcessedData)
    } catch (err) {
      console.error(err)
      const response = err.response
      const { message } = response?.data
      setErrorMessage(message)
    }
  }

  async function fetchResults() {
    try {
      const data = await Api.getResults(sCodes, params)
      return data
    } catch (err) {
      console.error(err)
      const response = err.response
      const { message } = response?.data
      setErrorMessage(message)
    }
  }

  const contextData = {
    // init data
    chatBotData,
    errorMessage,
    setErrorMessage,
    labels,
    currentStep,
    showFacility,
    setShowFacility,
    // stage management
    stage,
    setStage,
    onStageSetup,
    onStageClear,
    setCurrentStep,
    zIndex: zIndexInt,
    // general API
    fetchResults
  }

  return <GeneralContext.Provider value={{ ...contextData }}>{children}</GeneralContext.Provider>
}
