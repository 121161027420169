export default {
  characters: {
    USER: 'USER',
    BOT: 'BOT',
    SYSTEM: 'SYSTEM',
    STAGEMANAGER: 'STAGEMANAGER',
  },
  stepForSystem: {
    RESULTS: 'RESULTS',
  },
}