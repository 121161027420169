import React, { useEffect, useContext, useState } from 'react'
import cn from 'classnames'
import DefaultLogo from '@/assets/images/default_logo.png'

import Api from '@/provider/Api'
import { GeneralContext } from '@/provider/GeneralProvider'
import * as styles from './styles.module.scss'

const Com = ({ triggerNextStep, previousStep, action }) => {
  const {
    setCurrentStep,
    setShowFacility,
    stage
    //
  } = useContext(GeneralContext)

  const [results, setResults] = useState([])

  useEffect(() => {
    // Trigger the next step
    setCurrentStep(previousStep.id)
    doGetResult()
    triggerNextStep()
  }, [triggerNextStep])

  const handleOnClick = (fac) => {
    setShowFacility(fac)
  }

  const doGetResult = async () => {
    const { sCodes, sCodes_or, input } = stage
    const res = (await Api.getResults('accesstoken', sCodes, sCodes_or, input)) || null
    setResults(res)
    return
  }

  // Return null since we don't want to render anything for this step
  return (
    <div className={cn(styles.items)}>
      {results?.length > 0 ? (
        results.map((item, i) => {
          const { org_id, name1, name2, logo, description } = item
          const title = [name1 || '', name2 || ''].join(' ')

          return (
            <div
              key={org_id}
              className={cn(styles.card)}
              onClick={() => {
                handleOnClick(item)
              }}
            >
              {/* <Icon icon={icon} /> */}
              <div className={cn(styles.logo_container)}>
                <div
                  className={cn(styles.logo_image)}
                  style={{
                    background: `url(${logo || DefaultLogo}) no-repeat center center`
                  }}
                />
              </div>
              <div className={cn(styles.title)}>{title}</div>
            </div>
          )
        })
      ) : (
        <div>No results</div>
      )}
    </div>
  )
}

export default Com
