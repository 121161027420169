import axios from "axios";
let SERVER = "https://api.ww.mbdtst.com";

console.log("process.env.BUILD_FOR", process.env.BUILD_FOR);  

if (process.env.BUILD_FOR?.trim() == "dev") {
  SERVER = "http://localhost:8080";
}

if (process.env.BUILD_FOR?.trim() == "stage") {
  SERVER = "https://api.ww.mbdtst.com";
}

const headers = {};
const request = axios.create({
  baseURL: SERVER + "/wc/heatherai",
  headers,
});

const browserLanguage = () => {
  let l_lang;
  if (navigator.userLanguage)
    // Explorer
    l_lang = navigator.userLanguage;
  else if (navigator.language)
    // FF
    l_lang = navigator.language;
  else l_lang = "";

  // some languages has different standard between browser and BCP-47
  l_lang = l_lang.toLowerCase();

  console.log("browser language code:", l_lang);
  return l_lang;
};

const getQString = () => {
  const queryObj = {}
  if (!queryObj.lang) {
    // queryObj.lang = browserLanguage();
    queryObj.browserLang = browserLanguage();
  }

  let browserCountry = localStorage.getItem("currentCountry") || "";
  if (!queryObj.country) {
    queryObj.browserCountry = browserCountry;
  }
  const params = [];
  for (const key in queryObj) {
    params.push(`${key}=${queryObj[key]}`);
  }
  return params.join("&");

}

export default {
  async getTree() {
    const qString = getQString()
    const res = await request.get(`?${qString}`);
    const data = res?.data;
    return data
  },

  async getResults (accesstoken, sCodes, sCodes_or, input) {
    const params = {
      sCodes,
      sCodes_or,
      input,
    }
    const qString = getQString()
    const res = await request.post(`/action/RESULTS?${qString}`, params);
    const data = res?.data;
    // test
    return data 
  },
  async getFacility (org_id) {
    const qString = getQString()
    const res = await request.get(`/facility/${org_id}?${qString}`);
    const data = res?.data;
    return data

  }
}