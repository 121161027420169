// import ReportApi from "@provider/ReportApi";
import _ from 'lodash'

const regexMapping = {
  US: "^[0-9]{5}(-[0-9]{4})?$",
};

/**
 * Handles the json chatbot data to code functionality
 * @param {object} defaultData
 * @param {object} clientData
 * @returns {object}
 */
export const process = tree => {
  const steps = []
  _.keys(tree)?.map((k, i) => {
    const { question, options, action } = tree[k]
    const step_1 = { hideInput: true }
    let step_2 = null

    // message and option must be seperated. so we need go regenrate the id
    step_1['id'] = k

    if (action && !question && !options) {
      step_1['action'] = action
      step_1['custom'] = 'CustomStep_Skip'
    }

    if (question) {
      step_1['message'] = question
    }

    // === if there is option. we put it to next message ===
    if (options) {
      step_2 = {}
      step_2['id'] = `${k}.optionStep`
      step_1['trigger'] = step_2['id']
      step_2['options'] = options.map(o => {
        return {
          scode: o.scode,
          label: o.option,
          value: o.option,
          trigger: 'agent',
          action: o.action,
          operation: o.operation,
          metadata: { value: o.value },
          redirect_url: o.redirect_url
        }
      })
    }

    if (k === 'RESULTS') {
      step_1['trigger'] = 'RESULTS.resource'
    }

    // special inputs
    if (k === 'LOC_ZIP') {
      step_1['trigger'] = 'LOC_ZIP.optionStep'
      step_2 = {
        id: 'LOC_ZIP.optionStep',
        user: true,
        hideInput: false,
        validator: value => {
          if (isNaN(value) || value.length !== 5) {
            return 'Please enter a valid 5-digit zipcode.'
          }

          // regex for US zip code

          const zipCodeRegex = new RegExp(regexMapping['US']);

          if (!zipCodeRegex.test(value)) {
            return 'Please enter a valid 5-digit zipcode.'
          }

          return true
        },
        metadata: { action },
        trigger: 'agent'
      }
    }

    // push the steps
    if (step_1) {
      steps.push(step_1)
    }

    if (step_2) {
      steps.push(step_2)
    }
  })

  steps.push({
    id: 'end',
    // Define the next step based on the user's choice or a specific message
    // For example:
    message: 'You selected option {previousValue}',
    end: true
  })

  return steps
}

export default {
  process
}
