import React, { useEffect, useRef, useContext, createContext, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { GeneralContext } from '@/provider/GeneralProvider'

import CustomChatbot from '@/organism/CustomChatbot'
import Drawer_ResourceCard from '@/organism/Drawer_ResourceCard'

import close from '@/assets/icons/close.svg'
import angleLeft from '@/assets/icons/angle-left-solid.svg'

import * as styles from './styles.module.scss'

export default ({ className, show, setShow, ...rest }) => {
  const {
    chatBotData,
    errorMessage,
    stage,
    dialogueHistory,
    fetchResults,
    labels,
    currentStep,
    onClose,
    zIndex,
    showFacility,
    setShowFacility
    //
  } = useContext(GeneralContext)

  if (!chatBotData) return null

  const handleTest = () => {
    setShowFacility(prev => {
      if (prev) {
        return null
      } else {
        return { org_id: 20369 }
      }
    })
  }

  const handleCloseDrawer = () => {
    setShowFacility(null)
  }

  return (
    <div className={cn("heather_ai_main", className, styles.root, show ? styles.show : styles.hide)} style={{ zIndex }}>
      <div className={cn(styles.header)}>
        <div className={cn(styles.headerContainer)}>
          <div style={{ display: 'flex' }}>
            {showFacility ? (
              <div className={cn(styles.iconBack)} onClick={handleCloseDrawer}>
                <img src={angleLeft} />
                <span className={cn(styles.backText)}>BACK</span>
              </div>
            ) : null}
            {/* <button onClick={handleTest}>test</button> */}
          </div>
          <div>
            <div onClick={() => setShow(false)} className={cn(styles.iconCloseContainer)}>
              <img src={close} />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.chatboxContainer)}>
        <div className={cn(styles.chatbox)}>
          <Drawer_ResourceCard />
          <CustomChatbot />
        </div>
      </div>
      {/* 
      <div style={{ padding: 10 }}>
        Inputs: <br />
        AND: {stage?.sCodes?.join(',')}
        <br />
        OR: {stage?.sCodes_or?.join(',')}
        <br />
        {_.keys(stage?.input)?.map((key, index) => {
          return (
            <div key={index}>
              {key}: {stage?.input[key]}
            </div>
          )
        })}
      </div> */}
    </div>
  )
}
