// src/ChatButton.js
import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { GeneralProvider } from './provider/GeneralProvider'
import Chatbox from './components/template/Chatbox'

function Com({ accessCode, access, id, dataId, zIndex }) {
  const handleClick = () => {
    setShow(prev => !prev)
  }

  const [show, setShow] = useState(false)
  useEffect(() => {
    const triggerButton = document.querySelector(`#${dataId}`);
    if (triggerButton) {
      triggerButton.addEventListener('click', handleClick);

      // Cleanup function to remove the event listener
      return () => {
        triggerButton.removeEventListener('click', handleClick);
      };
    }
  }, [dataId]);

  return (
    <GeneralProvider {...{...zIndex}}>
      <Chatbox {...{show, setShow}} />
    </GeneralProvider>
  )
}

export default Com
