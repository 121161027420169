import React, { useContext, createContext, useState, useEffect } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import DefaultLogo from '@/assets/images/default_logo.png'
import phone from '@/assets/icons/phone-solid.svg'
import map from '@/assets/icons/mobile-checkins.svg'
import check from '@/assets/icons/circle-check.svg'

import { GeneralContext } from '@/provider/GeneralProvider'

import Api from '@/provider/Api'
import * as styles from './styles.module.scss'

export default ({ className, ...rest }) => {
  const {
    zIndex,
    showFacility,
    setShowFacility
    //
  } = useContext(GeneralContext)

  const [moreInfo, setMoreInfo] = useState(null)
  const [services, setServices] = useState(null)

  const isShow = showFacility?.org_id

  useEffect(() => {
    if (showFacility?.org_id) {
      doGetFacility(showFacility.org_id)
    } else {
      setMoreInfo(null)
    }
  }, [isShow])

  const handleClose = () => {
    setShowFacility(null)
  }

  // if (!isShow) return null

  const doGetFacility = async org_id => {
    const res = (await Api.getFacility(org_id)) || null

    // split AllSCodes by category_code, display scode_name (in the future, scode_label)
    if (res?.AllSCodes) {
      const _services = {}
      res.AllSCodes.forEach(scode => {
        const { category_code, scode_name, category_name } = scode
        if (!_services[category_code]) {
          _services[category_code] = { category_name, scodes: [] }
        }
        _services[category_code].scodes.push({ scode_name })
      })
      setServices(_services)
    }

    setMoreInfo(res)
    return
  }

  const {
    org_id,
    logo,
    description,
    name1,
    name2,
    street1,
    street2,
    region,
    city,
    zip,
    latitude,
    longitude,
    country = 'US',
    public_phone,
    phone_intake1,
    phone_intake2,
    phone_hotline,
    website,
    distance
  } = moreInfo || {}
  return (
    <div
      className={cn(className, styles.root, isShow ? styles.show : styles.hide)}
      {...rest}
      style={{ zIndex: zIndex + 1 }}
    >
      <div className={cn(styles.ModalScrollContainer)}>
        <div>
          {/* table */}
          <div className={cn(styles.header)}>
            <div>
              <div className={cn(styles.logo_container)}>
                <img src={logo || DefaultLogo} alt="logo" />
              </div>
            </div>
            <div className={cn(styles.titleContainer)}>
              <div className={cn(styles.title)}>{[name1, name2].join('\n')}</div>
              <a className={cn(styles.website)} href={website} target="_blank" rel="noreferrer">
                {website}
              </a>
            </div>
          </div>
          <div className={cn(styles.body)}>
            <div className={cn(styles.phone)}>
              <a href={`tel:${formatPhoneNumber(public_phone)}`}>
                <img className={cn(styles.icon)} src={phone} alt="phone" />
                {public_phone}
              </a>
            </div>
            <div className={cn(styles.phone_miles)}>
              <div className={cn(styles.address)}>
                <img className={cn(styles.icon)} src={map} alt="map" />
                {[street1, street2, city, region, zip]?.filter(a=>a).join(', ')} ({_.round(distance, 1) || '--'} miles) 
                <a
                  className={cn(styles.navigate)}
                  href={`https://www.google.com/maps/search/?api=1&query=${formatAddressForGoogleMaps(
                    [street1, city, region, zip].join(', ')
                  )}`}
                  target="_blank"
                >
                  map
                </a>{' '}
                
              </div>
            </div>
            <br />
            {_.keys(services)?.map((category_code, index) => {
              const { category_name, scodes } = services[category_code]
              return (
                <div key={index} className={cn(styles.services)}>
                  <div className={cn(styles.title)}>{category_name}</div>
                  <ul>
                    {scodes?.map((scode, index) => {
                      return (
                        <li key={index}>
                          <img src={check} />
                          {scode.scode_name}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '#'
  // Remove any non-numeric characters (except the plus sign)
  let formattedNumber = phoneNumber.replace(/[^\d+]/g, '')
  return formattedNumber
}

function formatAddressForGoogleMaps(address) {
  // Replace spaces with plus signs and encode special characters
  return encodeURIComponent(address.trim().replace(/\s+/g, '+'))
}
