export default {
  id: 'default',
  steps: [
    {
      id: 'intro',
      hideInput: true,
      metadata: {
        randomMessage: [
          "Hi there, I'm HOPE, your Bot friend. I'm grateful you are here today. Take my hand and come with me.  I'm here to provide help, hope and hugs during this time.",
          'Hello!!',
          'Bonjour!! How are you?',
          'Why are you here?'
        ]
      },
      trigger: 'main-options'
    },
    {
      id: 'main-options',
      hideInput: true,
      options: [
        {
          value: 'supportForMyself',
          label: "I'm looking for mental health support/tools for myself",
          metadata: {
            record: true,
            trigger: 'age-range'
          }
        },
        {
          value: 'supportForSomeone',
          label: "I'm trying to support someone struggling with their mental health",
          metadata: {
            record: true,
            trigger: 'age-range'
          }
        },
        {
          value: 'lostSomeoneBySuicide',
          label: 'I lost someone to suicide or know someone that died by suicide',
          metadata: {
            record: true,
            trigger: 'age-range'
          }
        },
        {
          value: 'feedback',
          label: 'Help us build the LivinConnected community by giving us feedback',
          metadata: {
            redirectLink: 'https://livinfoundation.org/feedback-form/',
            record: true,
            trigger: 'feedback-thankyou'
          }
        }
      ]
    },
    {
      id: 'age-range',
      hideInput: true,
      message: 'What is the age range of the person needing the support? (Choose from the following options):',
      trigger: 'age-options'
    },
    {
      id: 'age-options',
      hideInput: true,
      options: [
        {
          value: 'lessThan18',
          label: '0-18 Years Old',
          metadata: {
            record: true,
            trigger: 'support-kind'
          }
        },
        {
          value: 'moreThan18',
          label: '18+ Years Old',
          metadata: {
            record: true,
            trigger: 'support-kind'
          }
        }
      ]
    },
    {
      id: 'support-kind',
      hideInput: true,
      message: 'What kind of support are you looking for? (Choose from the following options):',
      trigger: 'support-kind-options'
    },
    {
      id: 'support-kind-options',
      hideInput: true,
      options: [
        {
          value: 'crisisSupport',
          label: 'Crisis Support',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'professionalSupport',
          label: 'Professional Support',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'peerSupport',
          label: 'Peer Support',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'shareMyStory',
          label: 'I want to share my story and connect with others',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'carePackage',
          label: 'A little hope in the form of a care package',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'financialAssitance',
          label: 'Financial Assistance',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        },
        {
          value: 'trainingEducation',
          label: 'Training, Education & Advocacy',
          metadata: {
            record: true,
            trigger: 'resource'
          }
        }
      ]
    },
    {
      id: 'resource',
      hideInput: true,
      message: "I'm here to walk alongside you. Here are a few things I would recommend as next steps."
    },
    {
      id: 'feedback-thankyou',
      hideInput: true,
      message: "Thank you for helping us continue to build the LivinConnected community. We're better together!",
      trigger: 'main-options'
    }
  ],
  endSteps: [
    {
      id: 'end-steps',
      hideInput: true,
      message:
        "Hopefully you'll find one of these resources beneficial. We also have a community of online humans ready to chat with you. Come say Hi!",
      trigger: 'chat-page-options'
    },
    {
      id: 'chat-page-options',
      hideInput: true,
      options: [
        {
          value: 'chatYes',
          label: 'Yes, take me there!',
          metadata: {
            redirectLink: '/sec_Chat',
            trigger: 'main-options'
          }
        },
        {
          value: 'chat',
          label: 'I need to find other support resources.',
          trigger: 'main-options'
        }
      ]
    }
  ],
  resourceSteps: [
    {
      id: 'supportForMyself-lessThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'supportForMyself-moreThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'supportForMyself-lessThan18-professionalSupport',
      hideInput: true,
      componentKeys: [
        'fastTracker',
        'fairview',
        'canvasHealth',
        'childMindInstitute',
        'adultMentalHealth',
        'synergy',
        'ellieMental',
        'childrensMn'
      ]
    },
    {
      id: 'supportForMyself-moreThan18-professionalSupport',
      hideInput: true,
      componentKeys: ['fastTracker', 'fairview', 'canvasHealth', 'adultMentalHealth', 'synergy', 'ellieMental']
    },
    {
      id: 'supportForMyself-lessThan18-peerSupport',
      hideInput: true,
      componentKeys: ['hive', 'kindlyHuman']
    },
    {
      id: 'supportForMyself-moreThan18-peerSupport',
      hideInput: true,
      componentKeys: ['faceIt', 'hive', 'kindlyHuman']
    },
    {
      id: 'supportForMyself-lessThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHuman']
    },
    {
      id: 'supportForMyself-moreThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHuman']
    },
    {
      id: 'supportForMyself-lessThan18-carePackage',
      hideInput: true,
      componentKeys: ['optumFlashCards']
    },
    {
      id: 'supportForMyself-moreThan18-carePackage',
      hideInput: true,
      componentKeys: ['optumFlashCards']
    },
    {
      id: 'supportForMyself-lessThan18-financialAssitance',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'supportForMyself-moreThan18-financialAssitance',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'supportForMyself-lessThan18-trainingEducation',
      hideInput: true,
      componentKeys: ['continued', 'afsp', 'nami', 'save', 'suicidePreventionCenter', 'take5']
    },
    {
      id: 'supportForMyself-moreThan18-trainingEducation',
      hideInput: true,
      componentKeys: ['continued', 'afsp', 'nami', 'save', 'suicidePreventionCenter', 'take5']
    },
    {
      id: 'supportForSomeone-lessThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'supportForSomeone-moreThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'supportForSomeone-lessThan18-professionalSupport',
      hideInput: true,
      componentKeys: [
        'fastTracker',
        'fairview',
        'canvasHealth',
        'childMindInstitute',
        'adultMentalHealth',
        'synergy',
        'ellieMental',
        'childrensMn'
      ]
    },
    {
      id: 'supportForSomeone-moreThan18-professionalSupport',
      hideInput: true,
      componentKeys: ['fastTracker', 'fairview', 'canvasHealth', 'adultMentalHealth', 'synergy', 'ellieMental']
    },
    {
      id: 'supportForSomeone-lessThan18-peerSupport',
      hideInput: true,
      componentKeys: ['hive', 'kindlyHuman']
    },
    {
      id: 'supportForSomeone-moreThan18-peerSupport',
      hideInput: true,
      componentKeys: ['faceIt', 'hive', 'kindlyHuman']
    },
    {
      id: 'supportForSomeone-lessThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHuman']
    },
    {
      id: 'supportForSomeone-moreThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHuman']
    },
    {
      id: 'supportForSomeone-lessThan18-carePackage',
      hideInput: true,
      componentKeys: ['optumFlashCards']
    },
    {
      id: 'supportForSomeone-moreThan18-carePackage',
      hideInput: true,
      componentKeys: ['optumFlashCards']
    },
    {
      id: 'supportForSomeone-lessThan18-financialAssitance',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'supportForSomeone-moreThan18-financialAssitance',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'supportForSomeone-lessThan18-trainingEducation',
      hideInput: true,
      componentKeys: [
        'continued',
        'afsp',
        'nami',
        'save',
        'suicidePreventionCenter',
        'take5',
        'mentalHealthAmerica',
        'nationalAlliance',
        'substanceAbuse'
      ]
    },
    {
      id: 'supportForSomeone-moreThan18-trainingEducation',
      hideInput: true,
      componentKeys: [
        'continued',
        'afsp',
        'nami',
        'save',
        'suicidePreventionCenter',
        'take5',
        'mentalHealthAmerica',
        'nationalAlliance',
        'substanceAbuse'
      ]
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-crisisSupport',
      hideInput: true,
      componentKeys: ['mn']
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-professionalSupport',
      hideInput: true,
      componentKeys: ['brighterDaysGrief', 'griefClub', 'ellieMental']
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-professionalSupport',
      hideInput: true,
      componentKeys: ['synergy', 'brighterDaysGrief', 'griefClub', 'ellieMental']
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-peerSupport',
      hideInput: true,
      componentKeys: ['brighterDaysGrief', 'griefClub']
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-peerSupport',
      hideInput: true,
      componentKeys: ['brighterDaysGrief', 'griefClub']
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHumanMain']
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-shareMyStory',
      hideInput: true,
      componentKeys: ['kindlyHumanMain']
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-carePackage',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-carePackage',
      hideInput: true,
      componentKeys: []
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-financialAssitance',
      hideInput: true,
      componentKeys: ['brighterDaysGriefAssistance', 'griefClubAssistance']
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-financialAssitance',
      hideInput: true,
      componentKeys: ['brighterDaysGriefAssistance', 'griefClubAssistance']
    },
    {
      id: 'lostSomeoneBySuicide-lessThan18-trainingEducation',
      hideInput: true,
      componentKeys: [
        'brighterDaysGriefAssistance',
        'griefClubAssistance',
        'afsp',
        'nami',
        'save',
        'suicidePreventionCenter',
        'take5'
      ]
    },
    {
      id: 'lostSomeoneBySuicide-moreThan18-trainingEducation',
      hideInput: true,
      componentKeys: [
        'brighterDaysGriefAssistance',
        'griefClubAssistance',
        'afsp',
        'nami',
        'save',
        'suicidePreventionCenter',
        'take5'
      ]
    }
  ],
  resources: {
    fastTracker: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/fastTracker.webp',
      title: 'FastTrackerMN',
      link: 'https://fasttrackermn.org/'
    },
    fairview: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/fairview.svg',
      title: 'M Health Fairview Adolescent Day Treatment Program',
      link: 'https://www.mhealthfairview.org/treatments/childrens-day-therapy-program'
    },
    canvasHealth: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/canvasHealth.webp',
      title: 'Canvas Health',
      link: 'https://www.canvashealth.org/'
    },
    childMindInstitute: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/childMindInstitute.svg',
      title: 'Child Mind Institute',
      link: 'https://childmind.org/about-us/'
    },
    adultMentalHealth: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/adultMentalHealth.webp',
      title: 'Adult Mental Health Programs and Services',
      link: 'https://mn.gov/dhs/people-we-serve/seniors/health-care/mental-health/programs-services/'
    },
    synergy: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/synergy.webp',
      title: 'Synergy eTherapy Online Counseling',
      link: 'https://www.synergyetherapy.com/'
    },
    hive: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/hive.webp',
      title: 'The Hive Certified Peer Support',
      link: 'https://hivemn.com/'
    },
    kindlyHuman: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/kindlyHuman.webp',
      title: 'Kindly Human Peer Support',
      link: 'https://www.kindlyhuman.com/communities/livin'
    },
    kindlyHumanMain: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/kindlyHuman.webp',
      title: 'Kindly Human',
      link: 'https://www.kindlyhuman.com/'
    },
    faceIt: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/faceIt.webp',
      title: 'Face it Foundation for Men Peer Support Specialists',
      link: 'https://www.faceitfoundation.org/'
    },
    optumFlashCards: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/optumFlashCards.webp',
      title: 'Optum Health Conversation Starter Flashcards',
      link: 'https://optumconversation.com/'
    },
    afsp: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/afsp.avif',
      title: 'AFSP (American Foundation for Suicide Prevention)',
      link: 'https://afsp.org/'
    },
    nami: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/nami.svg',
      title: 'NAMI (National Alliance on Mental Illness)',
      link: 'https://www.nami.org/Home'
    },
    save: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/save.webp',
      title: 'SAVE (Suicide Awareness Voices of Education)',
      link: 'https://save.org/'
    },
    suicidePreventionCenter: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/suicidePreventionCenter.webp',
      title: 'Suicide Prevention Resource Center',
      link: 'https://sprc.org/'
    },
    take5: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/take5.webp',
      title: 'Take 5 to Save Lives (in partnership with World Health Organization)',
      link: 'https://www.take5tosavelives.org/'
    },
    mentalHealthAmerica: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/mentalHealthAmerica.webp',
      title: 'Mental Health America (MHA) - Helping someone else',
      link: 'https://mhanational.org/im-looking-mental-health-help-someone-else'
    },
    nationalAlliance: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/nami.svg',
      title: 'National Alliance on Mental Health (NAMI) - Family Members and Caregivers',
      link: 'https://www.nami.org/Your-Journey/Family-Members-and-Caregivers'
    },
    substanceAbuse: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/substanceAbuse.webp',
      title: 'Substance Abuse and Mental Health Services Administration (SAMHSA) - How to talk to friends and family',
      link: 'https://www.samhsa.gov/mental-health/how-to-talk/friends-and-family-members'
    },
    brighterDaysGrief: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/brighterDaysGrief.webp',
      title: 'Brighter Days Family Grief Center Suicide Support',
      link: 'https://brighterdaysgriefcenter.org/'
    },
    brighterDaysGriefAssistance: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/brighterDaysGrief.webp',
      title: 'Brighter Days Family Grief Center for Advocacy and Assistance',
      link: 'https://brighterdaysgriefcenter.org/'
    },
    griefClub: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/griefClub.webp',
      title: 'The Grief Club of Minnesota Suicide Support',
      link: 'https://griefclubmn.org/'
    },
    griefClubAssistance: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/griefClub.webp',
      title: 'The Grief Club of Minnesota for Advocacy and Assistance',
      link: 'https://griefclubmn.org/'
    },
    ellieMental: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/ellieMental.webp',
      title: 'Ellie Mental Health',
      link: 'https://elliementalhealth.com/'
    },
    continued: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/continued.svg',
      title: '2B Continued',
      link: 'https://www.2bcontinued.org/'
    },
    mn: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/mn.jpeg',
      title: 'Minnesota Department of Human Services',
      link: 'https://mn.gov/dhs/people-we-serve/adults/health-care/mental-health/resources/crisis-contacts.jsp'
    },
    childrensMn: {
      category: 'Health',
      icon: 'brain',
      image: '/images/livin/logo/childrensMn.png',
      title: "Children's Minnesota",
      link: 'https://www.childrensmn.org/services/care-specialties-departments/behavioral-health-program/locations/'
    }
  }
}
