import React, { useEffect, useContext } from 'react';
import { GeneralContext } from '@/provider/GeneralProvider'
import _ from 'lodash'

const Com = ({ triggerNextStep, previousStep, steps }) => {
  const {
    chatBotData,
    onStageSetup,
    setCurrentStep
    //
  } = useContext(GeneralContext)


  useEffect(() => {
    setCurrentStep(previousStep)
    // Based on the result, decide which step to go to next
    let nextStepId = previousStep.action || previousStep.id

    let prevStepRawData = steps[previousStep.id]
    if (prevStepRawData?.metadata) {
      const { action } = prevStepRawData.metadata
      if (action) {
        nextStepId = action
      }
    }
   
    // collect user inputs for search
    let scode = ''
    let input = {}
    if (previousStep.id === 'LOC_ZIP.optionStep') {
      input['zip'] = previousStep.value
    } else  if (previousStep.id === 'LOC_DISTANCE.optionStep') {
      input['distance'] = previousStep.metadata?.value
    } else {
      scode = previousStep.scode
    }
    onStageSetup(scode, input, previousStep?.operation )

    // if there is redirect_url
    if (previousStep?.redirect_url) {
      window.open(previousStep.redirect_url, '_blank')
    } 

    // Trigger the next step
    triggerNextStep({ trigger: nextStepId });
  }, []);

  // Return null since we don't want to render anything for this step
  return null;
};

export default Com;