// src/wr-web-component.js
import 'custom-event-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import reactToWebComponent from 'react-to-webcomponent'
import HAI from './webComponent/HAI.js'

// Access the current script tag
const currentScript = document.currentScript

// Read an attribute from the script tag (e.g., data-token)
const access = currentScript.getAttribute('access')
const dataId = currentScript.getAttribute('trigger-id')
const zIndex = currentScript.getAttribute('z-index')


const HaiWebComponent = reactToWebComponent(props => <HAI {...props} {...{ access, dataId, zIndex }} />, React, ReactDOM, {
  props: {
    // accessCode: 'string',
    id: 'string'
  }
})

// Function to dynamically load CSS
const loadCSS = href => {
  if (!document.querySelector(`link[href="${href}"]`)) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    document.head.appendChild(link)
  }
}

class ExtendedHaiWebComponent extends HaiWebComponent {
  connectedCallback() {
    super.connectedCallback()
    const scriptUrl = new URL(currentScript.src)
    const cssUrl = new URL('heather-ai.css', scriptUrl)
    loadCSS(cssUrl.href)
  }
}

customElements.define('heather-ai', ExtendedHaiWebComponent)

// if there is no heather-ai element in the DOM, create one and attach to body
if (!document.querySelector('heather-ai')) {
  const hai = document.createElement('heather-ai')
  document.body.appendChild(hai)
}
