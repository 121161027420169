import React, { useContext, useEffect, createContext, useState } from 'react'
import cn from 'classnames'
import ChatBot from 'react-simple-chatbot'
import { GeneralContext } from '@/provider/GeneralProvider'

import CustomStep_Agent from '@/molecule/CustomStep_Agent'
import CustomStep_Skip from '@/molecule/CustomStep_Skip'
import CustomStep_Resource from '@/molecule/CustomStep_Resource'

import dummy from './dummy'

import * as styles from './styles.module.scss'

const bubbleStyle = {
  borderRadius: 12,
  color: '#002677',
  padding: 12,
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
}

const bubbleOptionStyle = {
  backgroundColor: '#faf8f2',
  border: '1px solid #002677',
  color: '#002677',
  cursor: 'pointer',
  padding: '8px 12px',
  marginBottom: 4,
  fontFamily: 'Optum Sans, Arial, sans-serif'
}

const footerStyle = {
  fontFamily: 'Optum Sans, Arial, sans-serif'
}

export default ({ className, ...rest }) => {
  const {
    chatBotData,
    errorMessage,
    setQueue,
    fetchResults,
    labels,
    stage
    //
  } = useContext(GeneralContext)

  const [chatData, setChatData] = useState(null)

  useEffect(() => {
    if (chatBotData) {
      // handle custom steps
      const newSteps = chatBotData?.map((item, index) => {
        const { custom, action, ...rest } = item
        const _c = {
          ...rest
        }
        _c.asMessage = false
        _c.replace = true

        if (custom) {
          switch (custom) {
            case 'CustomStep_Skip':
              _c.component = <CustomStep_Skip action={action} />
              break
            default:
              break
          }
        }

        return _c
      })

      // Agent and Resource are fixed custom steps
      newSteps.push({
        id: 'agent',
        component: <CustomStep_Agent />,
        asMessage: false,
        replace: true,
        waitAction: true
      })

      newSteps.push({
        id: 'RESULTS.resource',
        component: <CustomStep_Resource />,
        waitAction: true,
        trigger: 'RESULTS.optionStep'
      })

      setChatData(newSteps)
    }
  }, [chatBotData])

  if (chatData === null) return null

  console.log('chatData', chatData, chatBotData)
  console.log(stage)
  return (
    <ChatBot
      // handleEnd={e => handleEnd(e)}
      hideHeader={true}
      userAvatar={'https://healthtek.me/images/no-avatar.webp'}
      botAvatar="https://healthtek.me/images/hope-avatar-shadow.webp"
      // hideBotAvatar={false}
      // hideUserAvatar={true}
      enableSmoothScroll={true}
      userDelay={0}
      botDelay={0}
      avatarStyle={{ boxShadow: 'none', padding: 0 }}
      bubbleStyle={bubbleStyle}
      bubbleOptionStyle={bubbleOptionStyle}
      footerStyle={{ ...footerStyle }}
      inputStyle={{}}
      steps={chatData}
      className={cn(styles.chatbot)}
    />
  )
}
