@use "source/webComponent/theme" as *;

.show {
  display: flex;
}
.hide {
  display: none;
}

.root {

  // =========== version 1: side ============
  // // use mixin breakpoint-up
  // @include breakpoint-up(xl) {
  //   width: 50dvw;
  // }

  // @include breakpoint-up(lg) {
  //   width: 60dvw;
  // }

  // @include breakpoint-up(md) {
  //   width: 80dvw;
  // }
  // height: 100dvh;
  // top: 0px;
  // left: 0px;
  // position: fixed;

// =========== version 2:  center ============

  // center of the screen
 
  // transform: translate(-50%, -50%) translateZ(0);
  position: absolute;
  height: 100dvh; 
  width: 100dvw;
  right: 0px;
  bottom: 0px;
  @include breakpoint-up(md) {
    height: 80dvh; 
    min-width: 360px;
    max-width: 500px;
    width: 100%;
    right: 20px;
    bottom: 20px;
  }

  box-sizing: border-box;
  background-color: #ffffff33;
  border: 1px solid $color_border;
  font-family: $fontFamily;
  flex-direction: column;

  .header {
    height: 90px;
    background-color: $color_primary;
    .headerContainer {
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .iconCloseContainer {
        // width: calc(10dvh - 20px);
        height: 60px;
        cursor: pointer;
        img {
          height: 40px;
          width: 40px;
          margin: 10px;
          transition: transform 0.2s;
        }

        &:hover {
          img {
            transform: scale(1.1);
            transition: transform 0.2s;
          }
        }
      }

      .iconBack {
        height: 60px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacine_px;
        img {
          height: 40px;
          width: 40px;
          margin: 10px;
          transition: transform 0.2s;
        }

        &:hover {
          img {
            opacity: .8;
            transition: all 0.2s;
          }
          .backText {
            opacity: .8;
            transition: all 0.2s;
          }
        }

        .backText {
          font-size: 20px;
          font-weight: 600;
          color: $warmWhite;
          transition: all 0.2s;
        }
      }
    }
  }
  .chatboxContainer {
    margin-top: -40px;
    padding: $spacine_px;
    flex-grow: 1;
    height: calc(100% - 90px);
  }
  .chatbox {
    background-color: $color_background;
    border-radius: $spacing_borderradius;
    border: 1px solid $color_border;
    // shadow
    // box-shadow: 0 0px 15px 0px $color_border;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden; // for drawer
    display: flex;
    flex-direction: column;
    position: relative;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
