@use "source/webComponent/theme" as *;

.items {
  @include flexRow($alignY: stretch);
  justify-content: center;
  width: 100%;

  gap: 10px;

  @include breakpoint-up(md) {
    justify-content: flex-start;
  }

  .card {
    @include flexColumn();
    background-color: $warmWhite;
    padding: $spacine_px;
    width: 190px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    
    @include breakpoint-up(md) {
      // margin-inline-end: 10px;
    }

    svg {
      height: 56px;
      margin: 16px 0;
      width: auto;
    }

    .title {
      // color: #002677;
      font-size: $fontBody;
      font-weight: 300;
      margin-top: 10px;
    }

    p {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .top {
      @include flexRow($alignX: space-between, $wrap: nowrap);
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .icons svg {
      cursor: pointer;
      height: auto;
      fill: #002677;
      margin: 0;
      width: 24px;
      &:hover {
        filter: brightness(2);
      }
    }

    .logo_container {
      width: 100%;
      .logo_image { 
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 115px;
        min-width: 120px;
        width: 100%;
      }
    }

    .arrow svg {
      margin-inline-start: 4px;
      width: 16px;
    }
  }
}
