import React, { useEffect , useContext} from 'react';
import { GeneralContext } from '@/provider/GeneralProvider'

const Com = ({ triggerNextStep, previousStep, action }) => {
  const {
    setCurrentStep,
    onStageClear
    //
  } = useContext(GeneralContext)
  useEffect(() => {
    // Trigger the next step
    setCurrentStep(previousStep.id)

    if (previousStep.action === 'ENTRY') {
      onStageClear()
    }

    triggerNextStep({ trigger: action });
  }, []);

  // Return null since we don't want to render anything for this step
  return null;
};

export default Com;